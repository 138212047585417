<template>
    <div class="content" v-if="loading || empty">
        <img src="../assets/dong/loading.gif" alt="" v-if="loading">
        <img src="../assets/dong/empty.jpg" alt="" v-if="empty">
    </div>
</template>

<script>
    export default {
        name: "loading",
        props:{
            loading:{
                default: false,
                type: Boolean
            },
            empty:{
                default: false,
                type: Boolean
            }
        }
    }
</script>

<style scoped lang="less">
    .content{
        width: 100%;
        height: 17.5521vw;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            width: auto;
            height: 15.6250vw;
        }
    }
</style>
